.app {
  /* margin: 20px; */
  display: flex;
  justify-content: center;
  font-family: "Poppins";
  font-size: 14px;
  flex-direction: column;
  background-color: #181d27;
  height: 100vh;
}
.alert-container {
  position: fixed;
  bottom: 20px;
  left: 20px;
  z-index: 9999;
  pointer-events: none;
}

.heading {
  font-weight: 700;
  text-align: left;
  color: white;
}
.card-title {
  font-size: 20px;
}
.value {
  /* color: #6a6a6a; */
  text-align: right;
  color: rgba(255, 255, 255, 0.9) !important;
}

.gas-limit-container {
  cursor: pointer;
}
.gas-dropdown-container {
  display: flex;
  justify-content: space-between;
}
/* overrides */

.row {
  margin: 10px 0px !important;
}

.form-control {
  background-color: #ebebeb !important;
  border: 0 !important;
}

.card {
  border-radius: 25px !important;
  background-color: (30 36 49);
}
.connect-btn {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
  cursor: pointer;
  box-sizing: border-box;
  width: auto;
  font-family: Lexend, "PT Sans", sans-serif;
  min-height: 40px;
  font-size: 16px;
  line-height: 24px;
  transition: background-color 0.3s ease, transform 0.3s ease;
  border-radius: 8px;
  outline: none;
  padding: 0px 16px;
  gap: 8px;
  border-width: 1px;
  border-style: solid;
  border-color: transparent;
  border-image: initial;
  cursor: pointer;
  border: transparent !important;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.navbar {
  background: rgb(30 36 49);
  backdrop-filter: blur(5px);
  display: flex;
  justify-content: space-between;
  padding: 12px 50px 12px 50px !important;
  position: absolute !important;
  width: 100%;
  top: 0%;
}
.navbar-brand {
  color: white !important;
}
.navbar-right {
  display: flex;
  align-items: center;
  gap: 20px;
  color: white !important;
}
.chain-card {
  background-color: rgb(45 53 73);
  width: 100px;
  align-items: center;
  display: flex;
  justify-content: center;
  border-radius: 14px;
  padding: 12px;
  margin-top: 12px;
}

.tx-card {
  background-color: rgb(45 53 73);
  align-items: center;
  display: flex;
  justify-content: center;
  border-radius: 14px;
  padding: 12px;
  margin-top: 12px;
  width: fit-content;
  color: white;
  position: absolute;
  top: 10%;
  left: 4%;
  gap: 10px;
}
.table-white-text,
.table-white-text:hover {
  color: white;
}

.table-blue-text  .td > a,
.table-blue-text  .td > a:hover {
  color:  rgb(45 53 73) !important;
}

.table-white-text th,
.table-white-text td,
.table-white-text th:hover,
.table-white-text td:hover {
  color: white;
}
.td:hover {
  color: white;
}
